<template>
  <v-container
    grid-list-xl
    fluid
    style="padding-top: 0;">
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation>
      <v-layout
        v-if="isSuperAdmin()"
        wrap>
        <v-flex md12>
          <material-card
            :title="'Test API'"
            color="green"
            flat
            full-width
          >
            <v-expansion-panel
              popout
              @click.native="panelChange">
              <v-expansion-panel-content
                v-for="(item, i) in 3"
                :key="i"
              >
                <template v-slot:header>
                  <div v-if="item == 1">
                    {{ $t('Lấy thông tin voucher từ mã phát hành') }}
                  </div>
                  <div v-else-if="item == 2">
                    {{ $t('Mua voucher') }}
                  </div>
                </template>
                <template>
                  <div v-if="item == 1">
                    <v-container
                      grid-list-xl>
                      <v-form>
                        <v-layout
                          wrap
                          layout
                        >
                          <v-flex
                            xs12
                            sm6
                            md6
                          >
                            <v-text-field
                              :rules="[ruleRequiredValue]"
                              v-model="tokenCode"
                              :label="'Token code'"
                              class="required"
                            />
                          </v-flex>
                          <v-flex
                            xs12
                            sm6
                            md6
                          >
                            <v-text-field
                              :rules="[ruleRequiredValue]"
                              v-model="batchCode"
                              :label="'Batch code'"
                              class="required"
                            />
                          </v-flex>
                        </v-layout>
                      </v-form>
                      <v-spacer/>
                      <v-btn
                        color="success darken-1"
                        small
                        @click="onRequestGetVoucherInfo"
                      >{{ $t('Gửi') }}</v-btn>
                      <div
                        v-if="message && status"
                        :style="`border: 1px solid ${status == 1 ? 'green': 'red'}; padding: 10px; display: flex; flex-direction: column; justify-content: center;`"
                        class="mt-2">
                        <p><span class="font-weight-bold">Message:</span> <span :style="`color: ${status == 1 ? 'green': 'red'}`">{{ message }}</span></p>
                        <p><span class="font-weight-bold">Status:</span> {{ status }}</p>
                        <div v-if="status == 1">
                          <p><span class="font-weight-bold">Total quantity:</span> {{ info.totalQuantity }}</p>
                          <p><span class="font-weight-bold">Remain quantity:</span> {{ info.remainQuantity }}</p>
                          <p><span class="font-weight-bold">Price:</span> {{ info.price }}</p>
                          <p><span class="font-weight-bold">Name:</span> {{ info.name }}</p>
                          <p style="white-space: pre-wrap;"><span class="font-weight-bold">Description:</span> {{ info.description }}</p>
                          <p style="white-space: pre-wrap;"><span class="font-weight-bold">Conditions of use:</span> {{ info.conditionsOfUse }}</p>
                          <p><span class="font-weight-bold">Supplier:</span> {{ info.supplier }}</p>
                        </div>
                      </div>
                    </v-container>
                  </div>
                  <div v-else-if="item == 2">
                    <v-container grid-list-xl>
                      <v-form>
                        <v-layout
                          wrap
                          layout
                        >
                          <v-flex
                            xs12
                            sm6
                            md6
                          >
                            <v-text-field
                              :rules="[ruleRequiredValue]"
                              v-model="tokenCode"
                              :label="'Token code'"
                              class="required"
                            />
                          </v-flex>
                          <v-flex
                            xs12
                            sm3
                            md3
                          >
                            <v-text-field
                              :rules="[ruleRequiredValue]"
                              v-model="email"
                              :label="'Email'"
                              class="required"
                            />
                          </v-flex>
                          <v-flex
                            xs12
                            sm3
                            md3
                          >
                            <v-text-field
                              v-model="lastName"
                              :label="'Last name'"
                            />
                          </v-flex>
                          <v-flex
                            xs12
                            sm3
                            md3
                          >
                            <v-text-field
                              v-model="firstName"
                              :label="'First name'"
                            />
                          </v-flex>
                          <v-flex
                            xs12
                            sm3
                            md3
                          >
                            <v-text-field
                              v-model="phone"
                              :label="'Phone'"
                            />
                          </v-flex>
                        </v-layout>
                        <v-layout
                          wrap
                          layout>
                          <v-flex
                            xs12
                            sm12
                            md12
                            style="display: flex; align-items: center;"
                          >
                            <span>Thông tin voucher</span>
                            <v-icon
                              size="30"
                              color="success"
                              @click="onAddVoucherItem">mdi-plus</v-icon>
                          </v-flex>
                          <v-flex
                            v-for="(eVoucher, index) in eVouchers"
                            :key="eVoucher + index"
                            xs12
                            sm12
                            md12>
                            <v-layout
                              wrap
                              layout>
                              <v-flex
                                xs12
                                sm4
                                md4
                              >
                                <v-text-field
                                  :rules="[ruleRequiredValue]"
                                  v-model="eVoucher.batchCode"
                                  :label="'Batch code'"
                                  class="required"
                                />
                              </v-flex>
                              <v-flex
                                xs12
                                sm4
                                md4
                              >
                                <v-text-field
                                  :rules="[ruleRequiredValue, ruleMustGreaterThanZero]"
                                  v-model="eVoucher.quantity"
                                  :label="'Quantity'"
                                  class="required"
                                />
                              </v-flex>
                              <v-flex
                                xs12
                                sm1
                                md1
                              >
                                <v-icon
                                  size="30"
                                  color="red"
                                  float-right
                                  @click="onRemoveVoucherItem(index)">mdi-close</v-icon>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-form>
                      <v-spacer/>
                      <v-btn
                        color="success darken-1"
                        small
                        @click="onRequestBuyVoucher"
                      >{{ $t('Gửi') }}</v-btn>
                      <div
                        v-if="message && status"
                        :style="`border: 1px solid ${status == 1 ? 'green': 'red'}; padding: 10px; display: flex; flex-direction: column; justify-content: center;`"
                        class="mt-2">
                        <p><span class="font-weight-bold">Message:</span> <span :style="`color: ${status == 1 ? 'green': 'red'}`">{{ message }}</span></p>
                        <p><span class="font-weight-bold">Status:</span> {{ status }}</p>
                      </div>
                    </v-container>
                  </div>
                </template>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </material-card>
        </v-flex>
        <snack-bar
          v-for="snackbar in GET_SNACK_BAR"
          :key="snackbar.id"
          :item="snackbar" />
      </v-layout>
    </v-form>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import functionUtils from 'utils/functionUtils'
import validationRules from 'utils/validationRules'
export default {
  data: () => ({
    valid: true,
    message: null,
    status: null,
    info: {
      totalQuantity: null,
      remainQuantity: null,
      price: null,
      name: null,
      description: null,
      conditionsOfUse: null,
      supplier: null
    },
    tokenCode: null,
    batchCode: null,
    phone: null,
    firstName: null,
    lastName: null,
    email: null,
    eVouchers: [
      {
        batchCode: null,
        quantity: null
      }
    ]
  }),
  computed: {
    ...mapGetters([
      'GET_CURRENT_ACCOUNT'
    ])
  },
  watch: {
  },
  created () {
  },
  methods: {
    /**
     * Remove voucher item
     */
    onRemoveVoucherItem: function (index) {
      this.eVouchers.splice(index, 1)
    },
    /*
    * Add voucher item
    * */
    onAddVoucherItem: function () {
      this.eVouchers.push({
        batchCode: null,
        quantity: null
      })
    },
    // Required value
    ruleRequiredValue (value) {
      return validationRules.ruleRequiredValue(value)
    },
    /**
     * Check greater than zero
     */
    ruleMustGreaterThanZero (value) {
      return validationRules.ruleMustGreaterThan(value, 0)
    },
    panelChange (e) {
      if (e.target.classList.contains('v-expansion-panel-header--active')) {
        this.message = null
        this.status = null
      } else {
        this.message = null
        this.status = null
      }
    },
    /**
     * Request buy voucher
     */
    onRequestBuyVoucher: function () {
      if (this.$refs.form.validate()) {
        let filter = {
          tokenCode: this.tokenCode,
          vouchers: this.eVouchers,
          email: this.email,
          customerInfo: {
            firstName: this.firstName,
            lastName: this.lastName,
            phone: this.phone
          }
        }
        this.BUY_VOUCHER_FOR_EXTERNAL(filter).then(
          function (res) {
            let data = res.data
            this.message = data.message
            this.status = data.status
          }.bind(this)
        ).catch(
          function (error) {
            let data = error.response.data
            this.message = data.message
            this.status = data.status
          }.bind(this)
        )
      }
    },
    /**
     * Request get voucher info
     */
    onRequestGetVoucherInfo: function () {
      if (this.$refs.form.validate()) {
        let filter = {
          params: {
            tokenCode: this.tokenCode,
            batchCode: this.batchCode
          }
        }
        this.GET_VOUCHER_INFO_FOR_EXTERNAL(filter).then(
          function (res) {
            let data = res.data.data
            this.message = res.data.message
            this.status = res.data.status
            this.info.totalQuantity = data.total_quantity
            this.info.remainQuantity = data.remain_quantity
            this.info.price = data.info.price
            this.info.name = data.info.name
            this.info.description = data.info.description
            this.info.conditionsOfUse = data.info.conditions_of_use
            this.info.supplier = data.info.supplier
          }.bind(this)
        ).catch(
          function (error) {
            let data = error.response.data
            this.message = data.message
            this.status = data.status
          }.bind(this)
        )
      }
    },
    /**
     * Check is super admin
     */
    isSuperAdmin: function () {
      return functionUtils.isSuperAdmin(this.GET_CURRENT_ACCOUNT)
    },
    ...mapActions([
      'GET_VOUCHER_INFO_FOR_EXTERNAL',
      'BUY_VOUCHER_FOR_EXTERNAL'
    ])
  }
}
</script>

<style lang="scss" scoped>
/deep/.v-input--selection-controls.v-input .v-label {
  margin-bottom: 0px !important;
}
/deep/.required label::after {
  content: " *";
  color: red;
}
</style>
